<script lang="ts" setup>
import type { VChip } from 'vuetify/components'

const {
  color = 'primary',
  variant = 'tonal',
  density = 'default',
  size = 'small',
} = defineProps<{
  color?: VChip['$props']['color']
  size?: VChip['$props']['size']
  density?: VChip['$props']['density']
  variant?: VChip['$props']['variant']
  label?: boolean
  truncated?: boolean
}>()
</script>

<template>
  <v-chip
    :class="['skr-chip', { 'skr-chip--truncated': truncated }]"
    :color="color"
    :size="size"
    :density="density"
    :variant="variant"
    :label="label"
  >
    <slot />
  </v-chip>
</template>

<style lang="sass">
.skr-chip--truncated
  .v-chip__content
    display: block
    text-overflow: ellipsis
    overflow: hidden
</style>
